<template>
  <div class="teamBox">
    <nav-bar :right-text="$t('安置网络')" :title="$t('团队')" bg-color="#2c2e2f" right-color="#fff" title-color="#c6ba88"
      @click-right="router('/network')" />
    <div class="peopleNum">
      <p class="numBox">
        <img alt="" class="img" src="@/assets/img/team1.png">
        <span class="num">{{ direct_num }}</span>
        <span class="txt">{{ $t('我的直推人数') }}</span>
      </p>
      <p class="numBox">
        <img alt="" class="img" src="@/assets/img/team2.png">
        <span class="num">{{ team_num }}</span>
        <span class="txt">{{ $t('我的团队人数') }}</span>
      </p>
    </div>
    <div class="referrer">
      <p class="txt">{{ $t('我的推荐人') }}</p>
      <div class="userMsg">
        <img :src="httpPath + parent.head_img" alt="" class="userImg">
        <div class="msg">
          <p class="userName">
            <span class="name one">{{ parent?.nickname }}</span>
            <span class="levelImg">{{ parent?.level?.level_name }}</span>
          </p>
          <p class="number one">
            {{ $t('编号') }}：{{ parent.show_id || "" }}
            <img alt="" class="copyImg" src="@/assets/img/mine1.png" @click="copyDate(parent.show_id)">
          </p>
        </div>
      </div>
    </div>
    <div class="directList referrer">
      <p class="txt">{{ $t('直推列表') }}</p>
      <page-list :finished-text="$t('没有更多了')" :offset="0" api="/member/teamList"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="userMsg">
          <img :src="httpPath + item.head_img" alt="" class="userImg">
          <div class="msg">
            <p class="userName">
              {{ $t('编号') }}：{{ item.show_id }}
              <span v-if="item?.level" class="levelImg">{{ item?.level?.level_name }}</span>
            </p>
            <p class="number one">
              <img alt="" class="copyImg" src="@/assets/img/mine1.png" @click="copyDate(item.show_id)">
            </p>
          </div>
          <div class="perform">
            <p class="verLine" />
            <p class="perBox">
              <img alt="" class="perImg" src="@/assets/img/team3.png">
              <span class="txtS">{{ $t('累计贡献业绩') }}</span>
              <span class="priceNum">{{ item.amount }}</span>
            </p>
          </div>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        direct_num: 0,
        team_num: 0,
        parent: {},
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      teamTotal() {
        this.$get({
          url: `/member/teamTotal`,
          // loading: true,
          success: (res) => {
            this.direct_num = res.data.direct_num
            this.team_num = res.data.team_num
            this.parent = res.data.parent
          },
          tip: () => {
          }
        })
      },
      copyDate(id) {
        this.$copyText(JSON.stringify(id)).then(() => {
          this.$toast(this.$t('复制成功'))
        }, function () {
          this.$toast(this.$t('复制失败'))
        })
      },
    },
    created() {
      this.teamTotal()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .teamBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px 15px 70px;
    .peopleNum {
      display: flex;
      justify-content: space-around;
      background-color: #c8ad7f;
      padding: 30px 0;
      border-radius: 10px;
      .numBox {
        display: flex;
        align-items: center;
        gap: 5px 0;
        flex-direction: column;
        color: #6e552e;
        .img {
          width: 30px;
          height: 30px;
        }
        .num {
          font-size: 18px;
          font-weight: bold;
        }
        .txt {}
      }
    }
    .referrer {
      margin-top: 20px;
      padding: 15px;
      background-color: #343638;
      border-radius: 10px;
      .txt {
        font-weight: bold;
        font-size: 16px;
        color: #cbac7b;
      }
      .userMsg {
        display: flex;
        gap: 0 10px;
        margin-top: 15px;
        .userImg {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid #ffffff;
        }
        .msg {
          display: flex;
          flex-direction: column;
          gap: 5px 0;
          .userName {
            display: flex;
            align-items: center;
            gap: 0 10px;
            .name {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              max-width: 150px;
            }
            .levelImg {
              width: 60px;
              height: 30px;
              background-image: url("../../assets/img/team4.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              padding: 6px 5px;
              text-align: end;
            }
          }
          .number {
            color: #999999;
            font-size: 12px;
            display: flex;
            align-items: center;
            gap: 0 10px;
            .copyImg {
              width: 12px;
              height: 12px;
            }
          }
          .email {
            color: #999999;
            font-size: 12px;
          }
        }
      }
    }
    .directList {
      margin-top: 20px;
      background-color: #343638;
      padding: 15px;
      border-radius: 10px;
      .userMsg {
        border-bottom: 1px dashed #999999;
        padding-bottom: 10px;
        .msg {
          width: 170px;
          .userName {
            .name {
              max-width: 100px;
            }
          }
          .number {
            max-width: 190px;
            display: flex;
            align-items: center;
            gap: 0 10px;
            .copyImg {
              width: 12px;
              height: 12px;
            }
          }
          .email {
            max-width: 190px;
          }
        }
        .perform {
          display: flex;
          align-items: center;
          width: 100%;
          .verLine {
            width: 2px;
            height: 50px;
            background-color: #999999;
          }
          .perBox {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 5px 0;
            .perImg {
              width: 20px;
              height: 20px;
            }
            .txtS {
              color: #999999;
              font-size: 12px;
            }
            .priceNum {
              color: #cbceaf;
            }
          }
        }
      }
    }
  }
</style>
