<template>
  <div class="mineMsgBox">
    <nav-bar :title="$t('个人信息')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <div class="userBox">
      <p class="userList">
        <span class="left">{{ $t('头像') }}</span>
        <span class="right">
          <van-uploader :after-read="afterRead" :max-count="1" preview-size="30px">
            <img v-if="!showImg" alt="" class="uploadImg" src="@/assets/img/logo.png">
            <img v-else :src="showImg" alt="" class="uploadImg">
          </van-uploader>
          <van-icon color="#fff" name="arrow" />
        </span>
      </p>
      <p class="userList" @click="router('/alertName')">
        <span class="left">{{ $t('昵称') }}</span>
        <span class="right">
          <span class="name">{{ nickName }}</span>
          <van-icon color="#fff" name="arrow" />
        </span>
      </p>
      <p class="userList" @click="router('/alertPhone')">
        <span class="left">{{ $t('联系方式') }}</span>
        <span class="right">
          <span class="name">{{ phone }}</span>
          <van-icon color="#fff" name="arrow" />
        </span>
      </p>
      <!-- <p class="userList" @click="router('/alertPass')">
        <span class="left">{{ $t('登录密码') }}</span>
        <span class="right">
          <van-icon color="#fff" name="arrow" />
        </span>
      </p> -->
      <p class="userList" @click="router('/payPass')">
        <span class="left">{{ $t('交易密码') }}</span>
        <span class="right">
          <van-icon color="#fff" name="arrow" />
        </span>
      </p>

      <!-- <p class="userList" @click="router('/subReg')">
        <span class="left">{{ $t('开通子账号') }}</span>
        <span class="right">
          <van-icon color="#fff" name="arrow" />
        </span>
      </p> -->
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showImg: "",
        nickName: "",
        phone: "",
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取用户信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.showImg = this.httpPath + res.data.head_img
            this.nickName = res.data.nickname
            this.phone = res.data.phone
          },
          tip: () => {
          }
        })
      },
      // 选择并上传图片
      afterRead(e) {
        this.showImg = e.content
        let uploadFile = new FormData()
        uploadFile.append("file", e.file)
        this.$post({
          url: `/upload/local/image`,
          loading: true,
          upload: true,
          data: uploadFile,
          success: (res) => {
            this.editHeadImg(res.data)
          },
          tip: () => {
          }
        })
      },
      // 修改头像
      editHeadImg(img) {
        this.$post({
          url: `/member/editHeadImg`,
          loading: true,
          data: {
            head_img: img
          },
          success: (res) => {
            console.log(res)
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .mineMsgBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px 15px 70px;
    .userBox {
      background-color: #343638;
      padding: 7px 15px 0 15px;
      border-radius: 8px;
      .userList {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        .left {}
        .right {
          display: flex;
          align-items: center;
          gap: 0 15px;
          ::v-deep .van-uploader {
            border-radius: 50%;
            overflow: hidden;
            padding: 0;
            margin: 0;
            .van-uploader__wrapper {
              .van-uploader__input-wrapper {
                .uploadImg {
                  width: 30px;
                  height: 30px;
                }
              }
              //.van-uploader__upload {
              //  margin: 0;
              //}
              //.van-uploader__preview {
              //  margin: 0;
              //}
            }
          }
        }
      }
    }
  }
</style>
