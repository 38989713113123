<template>
  <div class="passwordBox">
    <!-- <nav-bar bg-color="transparent" left-arrow left-color="#fff" title=""/>
    <p class="logoBox">
      <img alt="" class="logo" src="@/assets/img/logo.png">
    </p>
    <div class="inputBox">
      <van-field v-model="account" :border="false" :label="$t('邮箱')" :placeholder="$t('请输入邮箱')"/>
      <van-field v-model="code" :border="false" :label="$t('验证码')" :placeholder="$t('请输入验证码')">
        <template #button>
          <span v-show="setCode" class="obtain" @click="getCode">{{ $t('获取') }}</span>
          <span v-show="!setCode" class="obtain" @click="noCode">{{ time }}S{{ $t('后获取') }}</span>
        </template>
</van-field>
<van-field v-model="password" :border="false" :label="$t('新密码')" :placeholder="$t('请输入新密码')" type="password" />
<van-field v-model="re_password" :border="false" :label="$t('新密码')" :placeholder="$t('请重复新密码')" type="password" />
</div>
<div class="btn" @click="registBtn()">{{ $t('保存') }}</div> -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        account: "",
        code: "",
        password: "",
        re_password: "",
        time: 10,
        setCode: true,
        setTime: null,
      }
    },
    methods: {
      registBtn() {
        if (!this.account) return this.$toast(this.$t("请输入邮箱"))
        if (!this.code) return this.$toast(this.$t("请输入验证码"))
        if (!this.password) return this.$toast(this.$t("请输入登录密码"))
        if (!this.re_password) return this.$toast(this.$t("请输入确认登录密码"))
        if (this.password !== this.re_password) return this.$toast(this.$t("两次输入的登录密码不一致"))
        this.$post({
          url: "/forget/password",
          loading: true,
          noToken: true,
          data: {
            account: this.account,
            code: this.code,
            password: this.password,
            re_password: this.re_password,
          },
          success: (res) => {
            this.toast({
              message: res.message,
              onClose: () => {
                this.$router.replace("login")
              },
            })
          },
          tip: () => {
          },
        })
      },
      getCode() {
        if (!this.account) return this.$toast(this.$t("请输入邮箱"))
        this.$post({
          url: "/send/code",
          loading: true,
          noToken: true,
          data: {
            to: this.account,
            scene: "forget_password"
          },
          success: () => {
            this.setCode = false
            clearInterval(this.setTime)
            this.setTime = null
            this.setTime = setInterval(() => {
              if (this.time > 0) {
                this.time--
              } else {
                clearInterval(this.setTime)
                this.setTime = null
                this.setCode = true
              }
            }, 1000)
            this.toast({
              message: this.$t("验证码获取成功"),
            })
          },
          tip: () => {
          }
        })
      },
      noCode() {

      },
    },
  }
</script>
<style lang='less' scoped>
  .passwordBox {
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 30px;
    background-image: url("../../../assets/img/login.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .logoBox {
      text-align: center;
      margin-bottom: 20px;
      .logo {
        width: 122px;
        height: 122px;
      }
    }
    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 20px 0;
      ::v-deep .van-field {
        background-color: #000000;
        border-radius: 7px;
        .van-field__label {
          color: #ffffff;
        }
        .van-field__value {
          .van-field__body {
            .van-field__control {
              color: #ffffff;
            }
            .van-field__button {
              .obtain {
                color: #cbac7b;
              }
            }
          }
        }
      }
    }
    .btn {
      padding: 10px 0;
      background: linear-gradient(125deg, #DBC397 0%, #BA9A66 100%);
      border-radius: 8px;
      margin-top: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #000000;
    }
  }
</style>
