module.exports = {
  "邮箱": "Email",
  "密码": "Password",
  "语言": "Language",
  "登录": "Login",
  "忘记密码": "Forgot password",
  "已阅读并同意": "Read and agree",
  "服务协议": "Service Agreement",
  "和": "and",
  "隐私政策": "Privacy Policy",
  "用户协议": "User Agreement",
  "请输入登录邮箱": "Please enter your login email",
  "请输入登录密码": "Please enter your login password",
  "选择语言": "Choose language",
  "验证码": "Code",
  "获取": "Obtain",
  "后获取": "Post obtain",
  "新密码": "New pass",
  "请输入邮箱": "Please enter your email",
  "请输入验证码": "Please enter the verification code",
  "请输入新密码": "Please enter a new pass",
  "请重复新密码": "Please repeat the new pass",
  "下载": "Download",
  "首页": "Home",
  "交易大厅": "Trade",
  "游戏": "Game",
  "团队": "Team",
  "我的": "My",
  "平台总权益股数": "Total equity shares of the platform",
  "实时数据展示": "Real time data",
  "股权总量": "Total equity",
  "奖金池金额": "Bonus pool",
  "价格趋势表": "Price Trend Table",
  "当前价格": "Current price",
  "近7天": "7 days",
  "近1月": "Last month",
  "近3月": "3 months",
  "公告": "Announcement",
  "公告详情": "Announcement details",
  "排队人数": "Queuing Num",
  "排队总金额": "Queuing amount",
  "权益币价格": "Equity coin price",
  "买单大厅": "Paying Hall",
  "卖单大厅": "Selling Hall",
  "发布买单": "Publish purchase order",
  "钱包地址": "Wallet Site",
  "发布数量": "Number of releases",
  "发布时间": "Release time",
  "价值": "Value",
  "我要匹配": "I need to match",
  "匹配": "Matching",
  "数量": "Quantity",
  "取消": "Cancel",
  "请输入数量": "Please enter the quantity",
  "购买数量": "Buy Num",
  "请输入购买数量": "Please enter the purchase quantity",
  "需要支付": "Payment required",
  "提交": "Submit",
  "发布卖单": "Publish sales orders",
  "到账": "Received",
  "手续费": "Handling fees",
  "出售数量": "Sell Num",
  "支出数量": "Pay Num",
  "游戏板块建设中": "Under construction in the gaming sector",
  "星": "Star",
  "我的直推人数": "My direct referrals",
  "我的团队人数": "My team size",
  "我的推荐人": "My recommender",
  "编号": "number",
  "直推列表": "Direct push list",
  "累计贡献业绩": "Contribution",
  "安置网络": "Placement Network",
  "会员编号": "Member ID",
  "会员邮箱": "Email",
  "会员等级": "VIP Lv",
  "会员业绩": "Membership performance",
  "非直推用户不可查看": "Non direct push users cannot view",
  "我的资产": "My assets",
  "股权余额": "Balance",
  "权益币余额": "Equity",
  "注册币余额": "Coin",
  "权益等级升级": "Equity level upgrade",
  "交易记录": "Transaction records",
  "注册会员": "Registered Member",
  "语言切换": "Language switching",
  "关于我们": "About Us",
  "设置": "Set up",
  "个人信息": "Personal information",
  "头像": "Avatar",
  "昵称": "Nickname",
  "登录密码": "Login password",
  "修改昵称": "Change nickname",
  "修改登录密码": "Change login password",
  "获取验证码": "Obtain",
  "重复密码": "Repeat pass",
  "收益": "Income",
  "铸造币余额": "mint",
  "兑换": "Exchange",
  "提现": "Withdrawal",
  "兑换注册币数量": "Exchange Coin quantity",
  "到账数量": "Received quantity",
  "兑换记录": "Exchange records",
  "提现记录": "Withdrawal records",
  "提现至": "Withdrawal to",
  "请选择": "Please select",
  "提现数量": "Withdrawal quantity",
  "到账金额": "Received amount",
  "审核中": "Under review",
  "已到帐": "Received",
  "提现失败": "Withdrawal failed",
  "您的审核失败": "Your review has failed",
  "注册币余额钱包": "Coin Balance Wallet",
  "转赠": "Transfer",
  "转赠记录": "Transfer records",
  "转赠人": "Transferor",
  "请输入转赠人编号": "Please enter the recipient's ID",
  "转赠数量": "Transfer quantity",
  "转赠明细": "Transfer details",
  "权益登记升级": "Equity registration upgrade",
  "悦享": "Yue Xiang",
  "可升级": "Upgradeable",
  "需": "Need",
  "注册币": "Coin",
  "立即升级": "Upgrade now",
  "买单": "Pay the bill",
  "卖单": "Vouchers of sale",
  "待匹配": "Match",
  "待付款": "Payment",
  "待确认": "Confirm",
  "已完成": "Completed",
  "已投诉": "Complained",
  "已取消": "Canceled",
  "单价": "Unit price",
  "交易地址": "BuyAddress",
  "交易时间": "BuyTime",
  "取消匹配": "Unmatched",
  "上传凭证": "Upload credentials",
  "获得": "Obtain",
  "注册记录": "Records",
  "我的注册币": "My Coin",
  "选择等级": "Select level",
  "需要扣除": "Deduction required",
  "请输入会员邮箱地址": "Please enter the member's email address",
  "推荐人": "Recommend",
  "请输入推荐人编号": "Please enter the recommend number",
  "安置人": "Placement",
  "请输入安置人编号": "Please enter the resettlement number",
  "安置位置": "Posit Place",
  "扣除币数": "Deduction of Coins",
  "安置人编号": "Resettlement ID",
  "推荐人编号": "Recommend number",
  "注册时间": "Registration time",
  "中文": "Chinese",
  "英文": "English",
  "支付设置": "Payment settings",
  "支付平台": "payment platform ",
  "微信支付": "WeChat payment",
  "支付宝支付": "Alipay payment",
  "删除": "Delete",
  "添加": "Add",
  "银行卡": "Bank card",
  "添加银行卡": "Add bank card",
  "绑定支付宝": "Bind Alipay",
  "绑定微信": "Bind WeChat",
  "姓名": "Name",
  "请输入姓名": "Please enter your name",
  "账号": "Account",
  "请输入账号": "Please enter your account number",
  "收款二维码": "Payment QR code",
  "请上传收款二维码": "Please upload the payment QR code",
  "绑定": "Binding",
  "绑定银行卡": "Bind bank card",
  "卡号": "Card number",
  "请输入卡号": "Please enter the card number",
  "开户行": "Opening bank",
  "请输入开户行": "Please enter the account opening bank",
  "保存": "Save",
  "没有更多了": "Nothing more",
  "开通": "Activate",
  "左区": "Left region",
  "右区": "Right region",
  "退出登录": "Log out",
  "拆分记录": "Split record",
  "拆分次数": "Split frequency",
  "拆分倍数": "Split multiple",
  "拆分时间": "Split time",
  "第": "Section",
  "次拆分": "split",
  "倍": "double",
  "联系方式": "Phone",
  "请输入联系方式": "Please enter your Phone",
  "修改联系方式": "Alert Phone",

  "请重复输入密码": "Please re-enter the password again",
  "两次密码不同": "Two different passwords",
  "请输入手机号": "Please enter your phone number",
  "请输入正确格式的手机号": "Please enter the correct formatted phone number",
  "发送成功": "Sending successful",
  "请同意服务协议与隐私政策": "Please agree to the service agreement and privacy policy",
  "请输入确认登录密码": "Please enter the confirmed login password",
  "两次输入的登录密码不一致": "The login passwords entered twice are inconsistent",
  "请输入交易密码": "Please enter the transaction password",
  "交易密码为6位数字格式": "The transaction password is in 6-digit format",
  "请输入确认交易密码": "Please enter the confirmation transaction password",
  "两次输入的交易密码不一致": "The transaction passwords entered twice are inconsistent",
  "请输入邀请码": "Please enter the invitation code",
  "请勾选用户注册协议": "Please check the user registration agreement",
  "请输入昵称": "Please enter your nickname",
  "兑换暂未开放": "Exchange not yet open",
  "请输入权益币数量": "Please enter the quantity of equity coins",
  "请选择注册等级": "Please select the registration level",
  "请输入会员邮箱": "Please enter the member's email address",
  "请选择安置位置": "Please select the placement location",
  "请输入申诉理由": "Please enter the reason for appeal",
  "请输入转增数量": "Please enter the quantity of transfer increase",
  "请输入提现数量": "Please enter the withdrawal quantity",
  "登录成功": "Login successful",
  "验证码获取成功": "Verification code obtained successfully",
  "复制成功": "Copy successful",
  "复制失败": "copy failed",
  "请选择语言": "Please select language",
  "累计销毁数量": "Total destruction number",
  "请输入匹配数量": "Please enter the matching quantity",
  "请输入出售数量": "Please enter the quantity for sale",
  "请输入卖出数量": "Please enter the selling quantity",
  "合计支出": "Total expenditure",
  "奖金池分红": "Bonus pool dividends",
  "等待排队配股": "Waiting in line for rights issue",
  "币安链地址": "Binance Chain Address",
  "请输入币安链钱包地址": "Please enter the Binance Chain wallet address",
  "交易密码": "Transaction password",
  "修改交易密码": "Change transaction password",
  "请输入重复密码": "Please enter a duplicate password",
  "客服": "customer service",
  "记录": "record",
  "请输入留言内容": "Please enter the message content",
  "提交成功": "Submitted successfully",
  "反馈记录": "Feedback Record",
  "回复时间": "Reply time",
  "回复": "reply",
  "搜索": "search",
  "请输入搜索ID": "Please enter your search ID",
  "扣除权益币数量": "Deducting the quantity of equity coins",
  "扣除注册币数量": "Deducting the number of registered coins",
  "兑换子币数量": "Number of sub coins exchanged",
  "请输入兑换数量": "Please enter the exchange quantity",
  "请输入交易密码": "Please enter the transaction password",
  "兑换注册币": "Exchange registered currency",
  "兑换子币": "Exchange sub currency",
  "扣除数量": "Subtract Quantity ",
  "请输入转赠数量": "Please enter the amount of gift to be transferred",
  "转增记录": "Conversion record",
  "支出": "expenditure",
  "子币余额": "Sub currency balance",
  "燃烧币余额": "Balance of Burning Coins",
  "中文（繁体）": "Chinese (Traditional)",
  "开通子账号": "Open sub account",
  "子账号": "sub account",
  "请选择等级": "Please select a level",
  "查看图片": "View Image",
  "子账号股权数": "Sub-account stock number",
  "USDT价格": "USDT price",
  "申诉": "Appeal",
  "申诉理由": "reason",
  "金额": "Amount",
  "编号": "Index",
  "尾页": "Last",
  "首页": "Head",
  "第": "The",
  "期": "stage",
  "开始游戏": "Start the game",
  "游戏暂未开始": "The game has not started yet"
}
