<template>
  <div class="gameBox">
    <!-- <nav-bar :title="$t('游戏')" />
    <div class="txt">
      <img alt="" class="img" src="@/assets/img/xx.png">
      {{ $t('游戏板块建设中') }}~
    </div> -->
    <div class="box">
      <div class="tit">{{ $t('游戏') }}</div>
      <div class="msg">
        <div class="s">
          {{ timeInfo.start_time || '-' }}~{{ timeInfo.end_time || '-' }}
        </div>
        <div class="n">
          <span>{{ userDate.equity || 0 }}</span>
        </div>
      </div>
      <div class="game">
      </div>
    </div>
    <div class="body">
      <div class="gamebox">
        <div class="tits">{{ $t('第') }}{{ game?.phases || '-' }}{{ $t('期') }}</div>
        <div class="nbox">
          <img alt="" class="img" src="@/assets/img/game1.png">
          <p @click="routerGame" v-if="isplay && game?.phases">{{ $t('开始游戏') }}</p>
          <p v-else>{{ $t('游戏暂未开始') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        timeInfo: {},
        userDate: {},
        game: {},
        isplay: false,//是否在时间范围内
      }
    },
    created() {
      this.getinfo()
      this.getTime()
      this.getGame()
    },
    methods: {
      getinfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          success: (res) => {
            this.userDate = res.data
          },
          tip: () => { }
        })
      },
      getTime() {
        this.$get({
          url: `/game/time`,
          success: (res) => {
            this.timeInfo = res.data
            if (res.data.start_time && res.data.end_time) {
              this.isplay = this.isCurrentTimeInRange(res.data.start_time, res.data.end_time)
            }
            console.log('是否在时间内', this.isplay);
          },
          tip: () => { }
        })
      },
      getGame() {
        this.$get({
          url: `/game`,
          success: (res) => {
            this.game = res.data
          },
        })
      },
      isCurrentTimeInRange(startTime, endTime) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const start = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1]);
        const end = parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1]);
        const current = currentHour * 60 + currentMinute;
        const isNextDay = start > end;

        if (isNextDay) {
          return current >= start || current <= end;
        } else {
          return current >= start && current <= end;
        }
      },
      routerGame() {
        window.location.href = `${window.location.origin}/game/#/`
      }
    },
  }
</script>

<style lang="less" scoped>
  .gameBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 0 0 130px;
    .txt {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      font-size: 20px;
      padding: 20px;
      .img {
        margin-bottom: 30px;
        width: 132px;
        height: 132px;
      }
    }
    .box {
      width: 100%;
      .tit {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 550;
        padding: 12px 0;
      }
      .msg {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0 10px 10px;
        .s {
          background: url('../../assets/img/i.png') no-repeat;
          background-size: 100% 100%;
          padding: 10px 50px 9px;
          color: #fff;
          font-size: 12px;
          line-height: 1.6;
          span {
            font-size: 14px;
            font-weight: 550;
          }
        }
        .n {
          background: #583F26;
          border-radius: 18px 0px 0px 18px;
          border: 1px solid #D9A044;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
          }
          span {
            padding: 3px 10px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 550;
          }
        }
      }
    }
    .body {
      width: 100%;
      padding: 15px;
      .gamebox {
        width: 100%;
        background: rgba(0, 0, 0, .5);
        border-radius: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        margin-bottom: 15px;
        .tits {
          width: 60%;
          text-align: center;
          background: url('../../assets/img/ff.png') no-repeat;
          background-size: 100% 100%;
          padding: 23px 28px 35px 21px;
          color: #F9D500;
          font-size: 15px;
          font-weight: 550;
          margin-top: -15px;
        }
        .nbox {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px 20px 30px;
          img {
            width: 120px;
            height: 120px;
            margin: 30px 0;
          }
          >p {
            color: #fff;
            font-size: 16px;
            font-weight: 550;
            background: url('../../assets/img/i.png') no-repeat;
            background-size: 100% 100%;
            padding: 14px 60px;
          }
        }
      }
    }
    .navbox {
      width: 22%;
      position: fixed;
      right: 0;
      bottom: 240px;
      >div {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: -6px;
          width: 38px;
          height: 38px;
          margin-right: -20px;
        }
        span {
          background: #583F26;
          border-radius: 18px 0px 0px 18px;
          border: 1px solid #583F26;
          color: #fff;
          font-size: 14px;
          padding: 4px 10px 5px 28px;
          font-weight: 550;
        }
      }
    }
  }
</style>
